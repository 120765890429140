import axios from 'axios';

const DEV_URL = 'http://localhost:4000/api'
const PROD_URL = 'https://dhav2c7jp7.execute-api.us-east-1.amazonaws.com/prod/api';

const API_URL = process.env.NODE_ENV === 'production' ? PROD_URL : DEV_URL;

const instance = axios.create({
  baseURL: API_URL
});

export default instance;
