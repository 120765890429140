import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import Button from '../components/Button';
import Logo from '../components/Logo';

const Header: React.FC = () => {
  const history = useHistory();

  return (
    <header className="bg-black py-3">
      <div className="flex flex-row justify-between items-center py-2 md:py-3 lg:py-4 py container">
        <Link to="/">
          <Logo />
        </Link>
        <Button
          title="Refer a brand"
          color="primary"
          rounded
          onClick={() => history.push('/brands/refer')}
        />
      </div>
    </header>
  );
};

export default Header;
