import React from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const Input: React.FC<InputProps> = ({ error = false, className, ...props }) => {
  const errorClass = error ? 'app-input--error' : '';

  return <input type="text" {...props} className={`app-input ${errorClass} ${className}`} />;
};

export default Input;
