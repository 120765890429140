import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import Input from '../components/Input';
import Button from '../components/Button';
import TextArea from '../components/TextArea';
import { postContact } from '../api/agape-service';
import ThankYou from '../components/ThankYou';

const GetInTouch: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = 'Name is required';
      }

      if (!values.email) {
        errors.email = 'Email Address is required';
      }

      return errors;
    },

    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        await postContact(values);
        setIsSubmitting(false);
        setShowModal(true);
      } catch (err) {
        setIsSubmitting(false);
        alert('Something went wrong!');
      }
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    setShowModal(false);
  };

  return (
    <>
      <section className="bg-black flex justify-center items-center pt-4 md:pt-6">
        <div className="form-container flex flex-col items-center pt-10">
          <span className="uppercase font-bold inline-block text-mandys-pink get-in-touch__title mb-2 md:mb-4">
            Get in touch
          </span>

          <p className="font-lato font-bold text-center text-pampas form__description--large">
            Please fill out our form and we will be in touch with lightening speed.
          </p>

          <form onSubmit={formik.handleSubmit} className="app-form">
            <Input
              type="text"
              placeholder="Name"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.name && formik.errors.name ? true : false}
              {...formik.getFieldProps('name')}
            />

            <Input
              type="email"
              placeholder="Email address"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.email && formik.errors.email ? true : false}
              {...formik.getFieldProps('email')}
            />

            <TextArea
              placeholder="Message"
              className="mb-6 lg:mb-8"
              error={formik.touched.message && formik.errors.message ? true : false}
              rows={5}
              {...formik.getFieldProps('message')}
            />

            <Button
              title="Submit"
              type="submit"
              buttonClass="w-full mb-6 lg:mb-8 py-2"
              disabled={!formik.isValid || isSubmitting}
              loading={isSubmitting}
            />
          </form>
          <Link to="/" className="text-mandys-pink font-medium text-base leading-4 mb-6 lg:mb-8">
            Back To Home
          </Link>
        </div>
      </section>
      <ThankYou show={showModal} showFooterLink={false} onCloseClick={handleModalClose} />
    </>
  );
};

export default GetInTouch;
