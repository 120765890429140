import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../components/Button';

const ContactUs: React.FC = () => {
  const history = useHistory();

  return (
    <section className="pt-10 pb-8 md:pt-16 md:pb-10 lg:pt-20 lg:pb-20 text-center bg-white">
      <div className="container">
        <Button
          title="Contact us"
          buttonClass="py-3 px-10 md:px-16 text-xl md:text-3xl leading-7 md:leading-9"
          color="info"
          rounded
          onClick={() => history.push('/contacts')}
        />
      </div>
    </section>
  );
};

export default ContactUs;
