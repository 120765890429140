import React from 'react';

interface LogoProps {
  logoClass?: string;
}

const Logo: React.FC<LogoProps> = ({ logoClass = '' }) => {
  return (
    <span className={`uppercase font-bold text-white inline-block app-logo ${logoClass}`}>
      AGAPE
    </span>
  );
};

export default Logo;
