import React from 'react';

import womensAcceries from '../assets/images/womens-acceries.png';
import jewelry from '../assets/images/jewelry.png';
import foodAndDrink from '../assets/images/food-and-drink.png';
import kidsAndBaby from '../assets/images/kids-and-baby.png';
import womensApparel from '../assets/images/womens-apparel.png';
import paperAndNovelty from '../assets/images/paper-and-novelty.png';
import beautyAndWellness from '../assets/images/beauty-and-wellness.png';
import homeDecor from '../assets/images/home-decor.png';

const products = [
  { title: 'Women’s Accessories', image: womensAcceries },
  { title: 'Jewelry', image: jewelry },
  { title: 'Food & Drink', image: foodAndDrink },
  { title: 'Kids & Baby', image: kidsAndBaby },
  { title: 'Apparel', image: womensApparel },
  { title: 'Paper & Novelty', image: paperAndNovelty },
  { title: 'Beauty & Wellness', image: beautyAndWellness },
  { title: 'Home Decor', image: homeDecor },
];

const ProductsByCategory: React.FC = () => {
  const renderProducts = () => {
    return products.map((product) => (
      <div
        key={product.title}
        className="flex justify-center items-center product"
        style={{ backgroundImage: `url(${product.image})` }}
      >
        <span className="inline-block text-center bg-white p-2 font-bold text-xl leading-7 product__title">
          {product.title}
        </span>
      </div>
    ));
  };

  return (
    <section className="pt-8 md:pt-12 lg:pt-16 pb-4 bg-white">
      <div className="container">
        <h4 className="mb-12 md:mb-20 lg:mb-24 text-center font-bold section-title">
          Products by category
        </h4>
        <div className="products">{renderProducts()}</div>
      </div>
    </section>
  );
};

export default ProductsByCategory;
