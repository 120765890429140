import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black flex justify-center items-center py-6">
      <span className="font-lato font-medium text-base text-white leading-4">
        © Agape. All Rights Reserved
      </span>
    </footer>
  );
};

export default Footer;
