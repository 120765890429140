import React from 'react';

import Banner from './Banner';
import ContactUs from './ContactUs';
import Header from './Header';
import Footer from './Footer';
import HowItWorks from './HowItWorks';
import ProductsByCategory from './ProductsByCategory';
import AboutUs from './AboutUs';

const Home: React.FC = () => {
  return (
    <>
      <Header />
      <Banner />
      <AboutUs />
      <ProductsByCategory />
      <HowItWorks />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
