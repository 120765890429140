import React, { useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';

import Input from '../components/Input';
import Logo from '../components/Logo';
import Button from '../components/Button';
import { Link } from 'react-router-dom';
import { postBrand } from '../api/agape-service';
import ThankYou from '../components/ThankYou';
// import { isValidEmail, isValidUrl } from '../utils';

const retailerOptions = [
  { value: 'I am new to wholesale', label: 'I am new to wholesale' },
  { value: '1-10', label: '1-10' },
  { value: '11-25', label: '11-25' },
  { value: '26-50', label: '26-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-250', label: '101-250' },
  { value: '251-500', label: '251-500' },
  { value: '501-1000', label: '501-1000' },
  { value: 'More than 1000', label: 'More than 1000' },
];

const SignUpAsABrand: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      brandName: '',
      website: '',
      name: '',
      email: '',
      numberOfRetailers: '',
      instagramHandle: '',
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.brandName) {
        errors.brandName = 'Brand name is required';
      }

      if (!values.website) {
        errors.website = 'Website URL is required';
      }

      // if (!isValidUrl(values.website)) {
      //   errors.website = 'Website Url is invalid';
      // }

      if (!values.name) {
        errors.name = 'Name is required';
      }

      if (!values.email) {
        errors.email = 'Email Address is required';
      }

      // if (!isValidEmail(values.email)) {
      //   errors.email = 'Email is invalid';
      // }

      if (!values.numberOfRetailers) {
        errors.numberOfRetailers = 'Number of retailers is required';
      }

      if (!values.instagramHandle) {
        errors.instagramHandle = 'Instagram Handle is required';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        await postBrand(values);
        setIsSubmitting(false);
        setShowModal(true);
      } catch (err) {
        setIsSubmitting(false);
        alert('Something went wrong!');
      }
    },
  });

  const getSelectValue = (value: any) => {
    const option = retailerOptions.find((item) => item.value === value);
    return option ? option : null;
  };

  const handleSelectValueChange = (value: any) => {
    formik.setFieldValue('numberOfRetailers', value.value);
  };

  const handleModalClose = () => {
    formik.resetForm();
    setShowModal(false);
  };

  return (
    <>
      <section className="bg-black flex justify-center items-center">
        <div className="form-container flex flex-col items-center pt-10">
          <Logo logoClass="text-mandys-pink" />
          <span className="block text-white text-center text-sm leading-4 py-2 mb-2 md:mb-3 lg:mb-4">
            Coming Soon!
          </span>
          <p className="font-lato font-bold text-center text-pampas form__description">
            Signup and be the first to sell as a brand on Agape.
          </p>

          <form onSubmit={formik.handleSubmit} className="app-form">
            <Input
              type="text"
              placeholder="Brand name"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.brandName && formik.errors.brandName ? true : false}
              {...formik.getFieldProps('brandName')}
            />

            <Input
              type="text"
              placeholder="Website url"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.website && formik.errors.website ? true : false}
              {...formik.getFieldProps('website')}
            />

            <Input
              type="text"
              placeholder="Your name"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.name && formik.errors.name ? true : false}
              {...formik.getFieldProps('name')}
            />

            <Input
              type="email"
              placeholder="Email address"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.email && formik.errors.email ? true : false}
              {...formik.getFieldProps('email')}
            />

            <Select
              placeholder="Number of retailers you work with?"
              name="numberOfRetailers"
              onChange={handleSelectValueChange}
              isSearchable={false}
              isClearable={false}
              options={retailerOptions}
              className="mb-6 lg:mb-8 app-select"
              classNamePrefix="app-select"
              value={getSelectValue(formik.values.numberOfRetailers)}
            />

            <Input
              type="text"
              placeholder="Instagram handle"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.instagramHandle && formik.errors.instagramHandle ? true : false}
              {...formik.getFieldProps('instagramHandle')}
            />

            <Button
              title="Submit"
              type="submit"
              buttonClass="w-full mb-6 lg:mb-8 py-2"
              disabled={!formik.isValid || isSubmitting}
              loading={isSubmitting}
            />
          </form>

          <Link
            to="/brands/refer"
            className="text-mandys-pink font-medium text-base leading-4 mb-8"
          >
            Refer A Brand
          </Link>

          <Link to="/" className="text-mandys-pink font-medium text-base leading-4 mb-8">
            Back To Home
          </Link>
        </div>
      </section>
      <ThankYou show={showModal} showFooterLink={false} onCloseClick={handleModalClose} />
    </>
  );
};

export default SignUpAsABrand;
