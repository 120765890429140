import React from 'react';
import { Link } from 'react-router-dom';
import crossImg from '../assets/images/cross.png';
interface ThankYouProps {
  show?: boolean;
  showFooterLink?: boolean;
  onCloseClick?: () => void;
}

const ThankYou: React.FC<ThankYouProps> = ({
  show = true,
  showFooterLink = true,
  onCloseClick = () => {},
}) => {
  const visibilityClass = show ? 'thank-you--show' : '';
  const backdropVisibilityClass = show ? 'backdrop--show' : '';

  return (
    <>
      <div
        className={`flex justify-center flex-col items-center fixed thank-you ${visibilityClass}`}
      >
        <button className="close-modal" onClick={onCloseClick}>
          <img src={crossImg} alt="Close modal" />
        </button>

        <button className="absolute top-0 right-0"> CLose </button>

        <span className="uppercase font-bold block text-mandys-pink thank-you__text mb-2 md:mb-4">
          Thank you
        </span>

        {showFooterLink && (
          <Link to="/" className="text-mandys-pink font-medium text-sm mt-4 md:mt-6 leading-4">
            Back To Home
          </Link>
        )}
      </div>

      <div className={`backdrop ${backdropVisibilityClass}`}></div>
    </>
  );
};

export default ThankYou;
