import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';

import Input from '../components/Input';
import Logo from '../components/Logo';
import Button from '../components/Button';
import ThankYou from '../components/ThankYou';

import { postReferBrand } from '../api/agape-service';

const ReferABrand: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      brandName: '',
      website: '',
      yourEmail: '',
      brandEmail: '',
    },
    validate: (values) => {
      const errors: any = {};

      if (!values.brandName) {
        errors.brandName = 'Brand name is required';
      }

      if (!values.website) {
        errors.website = 'Website URL is required';
      }

      // if (!isValidUrl(values.website)) {
      //   errors.website = 'Website Url is invalid';
      // }

      if (!values.yourEmail) {
        errors.yourEmail = 'Email Address is required';
      }

      // if (!isValidEmail(values.yourEmail)) {
      //   errors.yourEmail = 'Email is invalid';
      // }

      if (!values.brandEmail) {
        errors.brandEmail = 'Email Address is required';
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        const referBrand = {
          brandName: values.brandName,
          website: values.website,
          brandEmail: values.yourEmail,
          referredBrandEmail: values.brandEmail,
        };

        setIsSubmitting(true);
        await postReferBrand(referBrand);
        setIsSubmitting(false);
        setShowModal(true);
      } catch (err) {
        setIsSubmitting(false);
        alert('Something went wrong!');
      }
    },
  });

  const handleModalClose = () => {
    formik.resetForm();
    setShowModal(false);
  };

  return (
    <>
      <section className="bg-black flex justify-center items-center">
        <div className="form-container flex flex-col items-center pt-10">
          <Logo logoClass="text-mandys-pink" />
          <span className="block text-white text-center text-sm leading-4 py-2 mb-2 md:mb-4">
            Coming Soon!
          </span>
          <p className="font-lato font-bold text-center text-pampas form__description--large">
            We want to hear from you. Refer a brand you want to see on Agape.
          </p>

          <form onSubmit={formik.handleSubmit} className="app-form">
            <Input
              type="text"
              placeholder="Brand name"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.brandName && formik.errors.brandName ? true : false}
              {...formik.getFieldProps('brandName')}
            />

            <Input
              type="text"
              placeholder="Website url"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.website && formik.errors.website ? true : false}
              {...formik.getFieldProps('website')}
            />

            <Input
              type="email"
              placeholder="Your email"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.yourEmail && formik.errors.yourEmail ? true : false}
              {...formik.getFieldProps('yourEmail')}
            />

            <Input
              type="email"
              placeholder="Brand email address"
              className="mb-6 lg:mb-8"
              required
              error={formik.touched.brandEmail && formik.errors.brandEmail ? true : false}
              {...formik.getFieldProps('brandEmail')}
            />

            <Button
              title="Share"
              type="submit"
              buttonClass="w-full mb-6 lg:mb-8 py-2"
              disabled={!formik.isValid || isSubmitting}
              loading={isSubmitting}
            />
          </form>

          <Link
            to="/brands/refer"
            className="text-mandys-pink font-medium text-base leading-4 mb-8"
          >
            Refer Another Brand!
          </Link>

          <Link to="/" className="text-mandys-pink font-medium text-base leading-4 mb-8">
            Back To Home
          </Link>
        </div>
      </section>
      <ThankYou show={showModal} showFooterLink={false} onCloseClick={handleModalClose} />
    </>
  );
};

export default ReferABrand;
