import { Brand, Contact, ReferBrand, Retailer } from './agape-model';
import apiService from  './api-service';

export const postBrand = (brand: Brand) => {
  return apiService.post('/brands', brand);
}

export const postReferBrand = (referBrand: ReferBrand) => {
  return apiService.post('/referred-brands', referBrand);
}

export const postRetailer = (retailer: Retailer) => {
  return apiService.post('/retailers', retailer);
}

export const postContact = (contact: Contact) => {
  return apiService.post('/contacts', contact);
}