import React from 'react';

import howItWorks1 from '../assets/images/how-it-works-1.svg';
import howItWorks2 from '../assets/images/how-it-works-2.svg';
import howItWorks3 from '../assets/images/how-it-works-3.svg';

const HowItWorks: React.FC = () => {
  return (
    <section className="pt-12 md:pt-16 lg:pt-20 pb-4 bg-white">
      <div className="container">
        <h4 className="mb-12 md:mb-20 lg:mb-24 text-center font-bold section-title">
          How it works
        </h4>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 flex flex-col items-center px-8 mb-10 md:mb-2">
            <img src={howItWorks1} alt="Discover products" className="mb-4 md:mb-8" />
            <p className="text-center font-medium text-lg leading-6 md:text-xl md:leading-7 lg:text-2xl mb-4 align-middle inline">
              Signup to sell on Agape
            </p>
            <p className="text-center font-medium text-base leading-6 md:text-lg md:leading-7 lg:text-xl flex-1 align-middle inline">
              Join our community of diverse brands and be the first to sell on Agape.
            </p>
          </div>

          <div className="flex-1 flex flex-col items-center px-8 mb-10 md:mb-2">
            <img src={howItWorks2} alt="Buy products" className="mb-4 md:mb-8" />
            <p className="text-center font-medium text-lg leading-6 md:text-xl md:leading-7 lg:text-2xl mb-4 align-middle inline">
              Share products
            </p>
            <p className="text-center font-medium text-base leading-6 md:text-lg md:leading-7 lg:text-xl flex-1 align-middle inline">
              Share your products and get ready to be discovered by retailers.
            </p>
          </div>

          <div className="flex-1 flex flex-col items-center px-8 mb-10 md:mb-2">
            <img src={howItWorks3} alt="Get free returns" className="mb-4 md:mb-8" />
            <p className="text-center font-medium text-lg leading-6 md:text-xl md:leading-7 lg:text-2xl mb-4 align-middle inline">
              Get orders
            </p>
            <p className="text-center font-medium text-base leading-6 md:text-lg md:leading-7 lg:text-xl flex-1 align-middle inline">
              Sit back and relax as you get orders from retailers.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
