import React from 'react';
import Loader from './Loader';

type ButtonColor = 'default' | 'primary' | 'secondary' | 'info';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  rounded?: boolean;
  color?: ButtonColor;
  buttonClass?: string;
  loading?: boolean;
}

const colorClassMap = {
  default: 'btn-default',
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  info: 'btn-info',
};

const Button: React.FC<ButtonProps> = ({
  title,
  rounded = false,
  color = 'default',
  buttonClass = '',
  loading = false,
  ...props
}) => {
  const borderRadiusClass = rounded ? 'btn-rounded' : '';
  const colorClass = colorClassMap[color];

  return (
    <button className={`btn ${borderRadiusClass} ${colorClass} ${buttonClass}`} {...props}>
      {loading ? <Loader /> : title}
    </button>
  );
};

export default Button;
