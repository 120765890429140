import React from 'react';
import { useHistory } from 'react-router-dom';

import bannerImg from '../assets/images/banner.png';
import Button from '../components/Button';
import curveImg from '../assets/images/curve.png';

const Banner: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <section className="bg-black pt-10 lg:pt-16 xl:pt-24">
        <div className="container flex flex-col md:flex-row justify-between items-stretch lg:items-center py-6 pb-4 lg:pb-12">
          <div className="lg:mr-4 lg:pr-20 mb-8 lg:mb-0 banner__content">
            <span className="uppercase text-mandys-pink font-bold text-base leading-5 lg:text-lg lg:leading-6 mb-5 inline-block">
              Coming soon
            </span>
            <h1 className="font-bold text-white mb-6 lg:mb-8 banner__heading">
              Online wholesale for Black-owned brands
            </h1>
            <h3 className="text-white mb-12 lg:mb-16 lg:pr-32 xl:pr-40 banner__sub-heading">
              Order from hundreds of diverse sustainable brands all in one place
            </h3>
            <div className="flex flex-col md:flex-row">
              <Button
                title="Sign up as a retailer"
                color="secondary"
                rounded
                buttonClass="md:mr-4 py-3 mb-3 md:mb-0 form-button"
                onClick={() => history.push('/retailers/signup')}
              />
              <Button
                title="Sign up as a brand"
                color="primary"
                rounded
                buttonClass="py-3 mb-3 md:mb-0 form-button"
                onClick={() => history.push('/brands/signup')}
              />
            </div>
          </div>
          <div className="flex justify-end banner__img-container">
            <img
              src={bannerImg}
              alt="People showing various fashion trends"
              className="self-center hidden md:inline-block banner__img"
            />
          </div>
        </div>
        <img src={curveImg} alt="curve shape" className="w-full" />
      </section>
    </>
  );
};

export default Banner;
