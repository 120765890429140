import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GetInTouch from './GetInTouch';
import Home from './Home';
import ReferABrand from './ReferABrand';
import SignUpAsABrand from './SignUpAsABrand';
import SignUpAsARetailer from './SignUpAsARetailer';

const Main: React.FC = () => {
  return (
    <main className="flex-1 bg-black">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/brands/signup" component={SignUpAsABrand} exact />
        <Route path="/brands/refer" component={ReferABrand} exact />
        <Route path="/retailers/signup" component={SignUpAsARetailer} exact />
        <Route path="/contacts" component={GetInTouch} exact />
        <Route render={() => <Redirect to={{ pathname: '/' }} />} />
      </Switch>
    </main>
  );
};

export default Main;
