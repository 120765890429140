import React from 'react';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({ error = false, className, ...props }) => {
  const errorClass = error ? 'app-textarea--error' : '';

  return <textarea className={`app-textarea ${errorClass} ${className}`} {...props}></textarea>;
};

export default TextArea;
