import React from 'react';

const AboutUs: React.FC = () => {
  return (
    <section className="pt-1 lg:pt-3 pb-4 bg-white">
      <div className="container">
        <h4 className="mb-12 md:mb-20 lg:mb-24 text-center font-bold section-title">About us</h4>

        <p className="text-black text-center text-base md:text-xl lg:text-2xl leading-6 md:leading-7 lg:leading-8 mb-4 md:mb-6 lg:mb-8">
          Agape is a virtual community space for underrepresented brands to reach more independent
          stores. There is demand to support these brands but no clear avenue on how to do so. Our
          platform will curate a limited selection of brands every few months. They will range from
          home essentials to clothing with an emphasis on Black creators. If we, the people, want to
          see more diversity and equality in what we buy, it is up to us to build that future to
          sustain it. Agape not only serves to level the playing field... we seek to elevate it,
          every way possible.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
